const paymentModes = {
  PAY_WITH_CREDIT: "Покупка в кредит",
  PREPAID: "Безналичная оплата",
};

export const getPaymentModeTitle = (paymentMode) => {
  return paymentModes[paymentMode] || paymentMode;
};

export default paymentModes;
