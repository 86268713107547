<template>
  <el-form class="order-item-settings" label-position="top">
    <el-form-item v-for="(value, key) in items" :key="key" label="IMEI">
      <el-input
        size="medium"
        v-model="items[key]"
        placeholder="IMEI"
      ></el-input>
    </el-form-item>

    <el-button
      :disabled="loading"
      :icon="loading ? 'el-icon-loading' : ''"
      type="primary"
      plain
      round
      size="medium"
      @click="save"
      >Сохранить</el-button
    >
  </el-form>
</template>

<script>
import { updateOrderItem } from "@/api/sales";

export default {
  name: "OrderItemSettings",
  props: {
    orderId: {
      type: [Number, String],
      required: true,
    },
    quantity: {
      type: Number,
      required: true,
    },
    imeis: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      items: {},
    };
  },
  mounted() {
    for (let i = 0; i < new Array(this.quantity).length; i++) {
      const imei = this.imeis.length - 1 >= i ? this.imeis[i] : null;
      this.items = Object.assign({}, this.items, { ["imei" + i]: imei });
    }
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        const imeis = [];
        for (const imei in this.items) {
          if (this.items[imei]) {
            imeis.push(this.items[imei]);
          }
        }
        const res = await updateOrderItem(this.orderId, imeis);
        if (res.success) {
          this.$message.success("Успешно");
        } else {
          this.$message.error("Ошибка");
        }
        console.log(res);

        this.loading = false;
      } catch (e) {
        this.$message.error("Ошибка в запросе");
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.order-item-settings {
  .el-form-item {
    margin-bottom: 10px;
    label {
      line-height: 1;
      font-size: 13px;
    }
  }
}
</style>
