const deliveryModes = {
  DELIVERY_LOCAL: "Доставка в пределах населённого пункта",
  DELIVERY_PICKUP: "Самовывоз",
  DELIVERY_REGIONAL_PICKUP: "Региональная доставка до точки самовывоза",
  DELIVERY_REGIONAL_TODOOR: "Региональная доставка до дверей",
};

export const getDeliveryModeTitle = (deliveryMode) => {
  return deliveryModes[deliveryMode] || deliveryMode;
};

export default deliveryModes;
